/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties, FC, MutableRefObject, ReactElement, createElement, useEffect, useMemo, useRef, useState, UIEvent } from 'react';
import { Option } from '../Option';
import { Input, InputStyle } from './form-control/Input';
import { useTranslation } from 'react-i18next';
import { FCWithChildren } from '../../types/FCWithChildren';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import {
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import StringUtils from '../../utils/StringUtils';
import { createPortal } from 'react-dom';
import ContextMenuIcon from './icon/ContextMenuIcon';
import Tooltip from './Tooltip';

// Dropdown to be paired with other inputs and data sources - doesn't do much on it's own
export type Item = Option<string, string | number | readonly number[] | boolean> & {
  onClickShowChildren?: boolean;
  children?: Item[];
  iconName?: string; // At the moment only used by custom list renderer
  disabledTooltip?: string;
};

type SelectListMenuProps<TItem extends Item> = {
  id?: string;
  options: TItem[];
  instruction?: string;
  className?: string;
  style?: CSSProperties;
  isOpen: boolean;
  isFixed?: boolean;
  noMatchMessage?: string | ReactElement | FC;
  onClick?: (option: TItem) => void;
  customListItemRenderer?: FC<TItem & { highlightedText?: string }>;
  listWrapper?: FCWithChildren;
  selectedIndex?: number;
  disableTooltipIndex?: number; // Index of item that should not show tooltip
  width?: string;
  onBlur?: () => void;
  enableSearching?: boolean;
  autoStealFocusOnOpen?: boolean;
  placement?: Placement;

  children?: (props: Record<string, unknown>) => ReactElement;
  containingRef?: MutableRefObject<any>;
  footer?: string | ReactElement | FC;

  highlightedText?: string;
  blurOnClick?: boolean;
};

const defaultListWrapper: FCWithChildren = ({ children }) => <ul>{children}</ul>;

type MenuItemsProps<TItem extends Item> = {
  items: TItem[];
  onClick?: (option: TItem) => void;
  customListItemRenderer?: FC<TItem & { highlightedText?: string }>;
  highlightedText?: string;
  blurOnClick?: boolean;
  onBlur?: () => void;
  getItemProps: any;
  selectedIndex?: number;
  disableTooltipIndex?: number; // Index of item that should not show tooltip
};

const MenuItems: FC<MenuItemsProps<any>> = ({
  items,
  onClick,
  customListItemRenderer,
  highlightedText,
  blurOnClick,
  onBlur,
  getItemProps,
  selectedIndex,
  disableTooltipIndex,
}) => {
  const [isSubmenuOpenIndex, setIsSubmenuOpenIndex] = useState<number | null>(null);
  const [submenuPosition, setSubmenuPosition] = useState({ top: 0, left: 0 });

  const menuItemRefs = useRef<(HTMLLIElement | null)[]>([]);

  useEffect(() => {
    menuItemRefs.current = menuItemRefs.current.slice(0, items.length);
  }, [items.length]);

  const handleItemClick = (e: UIEvent<HTMLElement>, item: any, index: number) => {
    if ((e.target as HTMLElement).tagName === 'INPUT' && (e.target as HTMLInputElement).type === 'text') {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    const hasChildren = item.children && item.children.length > 0;
    if (hasChildren && item.onClickShowChildren) {
      handleShowSubItemClick(e, index);
    } else {
      onClick?.(item);
      blurOnClick && onBlur && onBlur();
    }
  };

  const handleShowSubItemClick = (e: UIEvent<HTMLElement>, index: number) => {
    e.stopPropagation();
    e.preventDefault();

    setIsSubmenuOpenIndex((prev) => (prev === null ? index : null));

    const rect = menuItemRefs.current[index]?.getBoundingClientRect();
    if (rect) {
      setSubmenuPosition({
        top: rect.top,
        left: rect.right + 5,
      });
    }
  };

  return (
    <>
      {items.map((item, i) => {
        if (item.hasDivider) {
          return (
            <div key={item.id} role="menuitem" className="border-gray-5 text-dpm-14 cursor-default border-b px-2 font-medium">
              {item.text}
            </div>
          );
        }

        const hasChildren = item.children && item.children.length > 0;
        return (
          <li
            key={`${item.id}-${item.text}-${item.value}`}
            ref={(el) => (menuItemRefs.current[i] = el)}
            {...getItemProps({
              ...mouseAndKeyboardCallbackProps(item.disabled ? undefined : (e) => handleItemClick(e, item, i)),
            })}
            data-cy={`option-${item.id}`}
            className={`select-none px-4 py-2 ${isSubmenuOpenIndex === i || selectedIndex === i ? 'bg-gray-100' : ''} ${item.disabled ? 'cursor-default opacity-50' : onClick ? 'cursor-pointer' : 'cursor-default'} text-primary-1 ${onClick ? 'group hover:bg-gray-100 hover:text-black' : ''}`}
          >
            <Tooltip
              text={disableTooltipIndex === i ? '' : item.disabled && !!item.disabledTooltip ? item.disabledTooltip : item.text}
              truncatedTextMode={!item.disabled || !item.disabledTooltip}
            >
              {(tooltip) => (
                <div {...tooltip} className="flex flex-grow items-center justify-between">
                  {customListItemRenderer ? (
                    createElement(customListItemRenderer, { ...item, highlightedText })
                  ) : (
                    <div>{StringUtils.highlightText(item.text, highlightedText)}</div>
                  )}
                  {hasChildren && !item.onClickShowChildren && (
                    <button
                      aria-haspopup="true"
                      data-cy="context-menu"
                      className="hover:border-primary-2 hover:bg-primary-2 relative cursor-pointer select-none rounded-md border border-transparent p-1 opacity-0 hover:bg-opacity-25 group-hover:opacity-100"
                      {...mouseAndKeyboardCallbackProps((e) => handleShowSubItemClick(e, i))}
                    >
                      <ContextMenuIcon className="h-4 w-4 rotate-90" />
                    </button>
                  )}
                </div>
              )}
            </Tooltip>

            {hasChildren &&
              isSubmenuOpenIndex === i &&
              createPortal(
                <ul
                  className="border-1 z-popover fixed min-w-48 overflow-y-auto rounded-md border-gray-200 bg-white shadow-md"
                  style={{
                    top: submenuPosition.top,
                    left: submenuPosition.left,
                  }}
                >
                  <MenuItems
                    items={item.children}
                    onClick={onClick}
                    customListItemRenderer={customListItemRenderer}
                    highlightedText={highlightedText}
                    blurOnClick={true}
                    onBlur={() => setIsSubmenuOpenIndex(null)}
                    getItemProps={getItemProps}
                  />
                </ul>,
                document.body,
              )}
          </li>
        );
      })}
    </>
  );
};

export const SelectListMenu = <TItem extends Item>(props: SelectListMenuProps<TItem>) => {
  const {
    id,
    options,
    instruction,
    onClick,
    className,
    style,
    isOpen,
    noMatchMessage,
    customListItemRenderer,
    listWrapper: ListWrapper = defaultListWrapper,
    selectedIndex,
    width,
    onBlur,
    enableSearching,
    containingRef,
    children,
    footer,
    autoStealFocusOnOpen = true,
    placement = 'bottom-start',
    highlightedText,
    blurOnClick = true,
    disableTooltipIndex,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation('common');

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (o) => !o && onBlur && onBlur(),
    middleware: [
      offset(10),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          const triggerWidth = rects.reference.width;
          Object.assign(elements.floating.style, {
            minWidth: `${triggerWidth}px`,
            maxWidth: '600px',
            width: 'auto',
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
    placement,
    strategy: 'fixed',
  });

  const click = useClick(context, { keyboardHandlers: false });
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const focus = useFocus(context);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([click, dismiss, role, focus]);

  useEffect(() => {
    if (containingRef?.current) {
      refs.setReference(containingRef?.current);
    }
  }, [containingRef, refs]);

  const filteredOptions = useMemo(() => {
    if (!searchTerm) {
      return options;
    }

    const search = searchTerm.toLocaleLowerCase();
    return options.filter((opt) => opt.text.toLocaleLowerCase().indexOf(search) > -1);
  }, [options, searchTerm]);

  useEffect(() => {
    if (!isOpen) {
      setSearchTerm('');
    }
  }, [isOpen]);

  const prevFocus = useRef<HTMLElement | null>(null);
  useEffect(() => {
    const menu = refs.floating.current;
    if (!filteredOptions.length || !menu || !autoStealFocusOnOpen) return;

    if (isOpen) {
      prevFocus.current = document.activeElement as HTMLElement;

      const listItems = [...menu.querySelectorAll('li')];
      const firstItem = listItems[0];
      const lastItem = listItems[listItems.length - 1];

      firstItem.focus();

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowDown') {
          const nextItem = document.activeElement?.nextElementSibling as HTMLElement;
          nextItem?.focus();
          e.preventDefault();
        } else if (e.key === 'ArrowUp') {
          const prevItem = document.activeElement?.previousElementSibling as HTMLElement;
          prevItem?.focus();
          e.preventDefault();
        } else if (e.key === 'Escape') {
          onBlur && onBlur();
        } else if (e.key === 'Tab') {
          if (e.shiftKey && document.activeElement === firstItem) {
            e.preventDefault();
            prevFocus.current?.focus();
            prevFocus.current = null;
            onBlur && onBlur();
          } else if (!e.shiftKey && document.activeElement === lastItem) {
            e.preventDefault();
            prevFocus.current?.focus();
            prevFocus.current = null;
            onBlur && onBlur();
          }
        }
      };

      menu.addEventListener('keydown', handleKeyDown);
      return () => {
        menu.removeEventListener('keydown', handleKeyDown);
      };
    } else {
      prevFocus.current?.focus();
      prevFocus.current = null;
    }
  }, [autoStealFocusOnOpen, onBlur, filteredOptions, isOpen, refs.floating]);

  return (
    <>
      {children &&
        children({
          ref: containingRef?.current ? undefined : refs.setReference,
          ...getReferenceProps(),
        })}
      {isOpen && (
        <div
          id={id}
          ref={refs.setFloating}
          style={{ ...floatingStyles, zIndex: 9998, maxWidth: refs.reference.current?.getBoundingClientRect().width, ...style }}
          {...getFloatingProps()}
          className={`${width || 'w-full'} min-w-dropdown-box border-1 max-h-96 overflow-y-auto rounded-md border-gray-100 bg-white pt-2 shadow-lg empty:border-0 group-hover:block ${className}`}
          data-cy={`select-list-menu${(refs.reference.current as HTMLElement)?.getAttribute('data-cy') ? '-' + (refs.reference.current as HTMLElement)?.getAttribute('data-cy') : ''}`}
        >
          {instruction && (
            <>
              {}
              <div className="text-primary-1 px-4 py-2" data-cy="instruction" role="presentation">
                {instruction}
              </div>
              <hr className="border-gray-5 border-0 border-b-2" />
            </>
          )}
          {filteredOptions.length === 0 && noMatchMessage && (
            <div className="px-4 py-1 italic" data-cy="no-match" role="alert" aria-live="assertive">
              {typeof noMatchMessage === 'function' ? createElement(noMatchMessage) : noMatchMessage}
            </div>
          )}
          {enableSearching && (
            <div className="px-1 py-2">
              <Input
                style={InputStyle.MINIMAL}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t('list.filter.search')}
                aria-label={t('aria-label.search')}
              />
            </div>
          )}
          <ListWrapper>
            <MenuItems
              items={filteredOptions}
              onClick={onClick}
              customListItemRenderer={customListItemRenderer}
              highlightedText={highlightedText}
              blurOnClick={blurOnClick}
              onBlur={onBlur}
              getItemProps={getItemProps}
              selectedIndex={selectedIndex}
              disableTooltipIndex={disableTooltipIndex}
            />
          </ListWrapper>
          <div className="sticky bottom-0 bg-white p-1 empty:hidden">{typeof footer === 'function' ? createElement(footer) : footer}</div>
        </div>
      )}
    </>
  );
};
